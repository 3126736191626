<template>
  <setup-table
    :title="`Vehicle Type`"
    :items="items"
    :headers="headers"
    :loading="loading"
    :clearItem="!showForm || !editedItem.id"
    @createItem="createItem()"
    @editItem="editItem"
    @deleteItems="deleteItems"
  >
    <template #itemForm v-if="showForm">
      <vehicle-type-form
        ref="vehicleTypeForm"
        :value="editedItem"
        :tripTypes="tripTypes"
        @submit="saveItem"
        @closeForm="closeForm"
      ></vehicle-type-form>
    </template>

    <template #[`item.synced`]="{ item }">
      <v-icon small v-if="item.synced">mdi-sync</v-icon>
    </template>
    <template #[`item.staffUse`]="{ item }">
      <v-icon small>{{ item.staffUse ? 'mdi-check' : 'mdi-close' }}</v-icon>
    </template>
    <template #[`item.autoAssign`]="{ item }">
      <v-icon small>{{ item.autoAssign ? 'mdi-check' : 'mdi-close' }}</v-icon>
    </template>
    <template #[`item.overrideRate`]="{ item }">
      <v-icon small>{{ item.overrideRate ? 'mdi-check' : 'mdi-close' }}</v-icon>
    </template>
    <template #[`item.vehicleRate`]="{ item }">
      <p class="mb-0">{{ item.overrideRate ? toMoney(item.vehicleRate) : 'N/A' }}</p>
    </template>
  </setup-table>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { GET_VEHICLE_TYPES, SAVE_VEHICLE_TYPE, DELETE_VEHICLE_TYPES } from '@/store/modules/VehicleType/actions';
import { GET_TRIP_TYPES } from '@/store/modules/TripType/actions';
import SetupTable from './SetupTable.vue';
import VehicleTypeForm from './VehicleTypeForm.vue';
import { toMoney } from '@/util';
import VEHICLE_TYPES from '@/shared/types';

export default {
  name: 'VehicleTypeSetupTable',
  inject: ['eventHub'],
  components: { SetupTable, VehicleTypeForm },
  data() {
    return {
      toMoney,
      VEHICLE_TYPES,
      showForm: false,
      items: [],
      editedItem: {},
      loading: true,
    };
  },
  computed: {
    ...mapGetters('app', ['isRoutingClient']),
    ...mapGetters('vehicleType', ['vehicleTypes']),
    ...mapGetters('tripType', ['tripTypes']),
    ...mapGetters('additionalTransportation', ['additionalTransportations']),
    headers() {
      const h = [
        { text: 'Type', value: 'name' },
        { text: 'Staff Use', value: 'staffUse' },
        { text: 'Auto Assign', value: 'autoAssign' },
        { text: 'Override Trip Rate', value: 'overrideRate' },
        { text: 'Vehicle Rate', value: 'vehicleRate' },
      ];
      if (this.isRoutingClient) h.unshift({ text: '', value: 'synced' });
      return h;
    },
  },
  mounted() {
    this.fetchItems();
  },
  methods: {
    ...mapActions('vehicleType', [GET_VEHICLE_TYPES, SAVE_VEHICLE_TYPE, DELETE_VEHICLE_TYPES]),
    ...mapActions('tripType', [GET_TRIP_TYPES]),
    async fetchItems() {
      await this.getVehicleTypes({ forTable: true });
      this.items = this.vehicleTypes;
      const charters = this.additionalTransportations.filter((e) => e.type == 'charter');
      const contractors = this.additionalTransportations.filter((e) => e.type == 'contractor');
      const rentals = this.additionalTransportations.filter((e) => e.type == 'rental');
      if (!charters.length) this.items = this.items.filter((e) => e.vehicleType != this.VEHICLE_TYPES.APPROVED_CHARTER);
      if (!contractors.length) this.items = this.items.filter((e) => e.vehicleType != this.VEHICLE_TYPES.CONTRACTOR);
      if (!rentals.length) this.items = this.items.filter((e) => e.vehicleType != this.VEHICLE_TYPES.RENTAL_DEALERSHIP);
      await this.getTripTypes();
      this.loading = false;
    },
    createItem() {
      this.showForm = true;
      this.editedItem = { id: 0, name: '', ttOnly: this.isRoutingClient ? true : false };
    },
    editItem(item) {
      this.editedItem = { ...item };
      this.showForm = true;
    },
    async saveItem(item) {
      try {
        const r = await this.saveVehicleType(item);
        if (r && r.id) {
          this.$myalert.success('Vehicle Type saved');
          await this.fetchItems();
          this.editedItem = { ...item, id: r.id };
        }
      } catch (error) {
        this.$myalert.error(error.message);
      }
    },
    async deleteItems(items) {
      const yes = await this.$myconfirm(
        `Are you sure you want to delete ${
          items.length > 1 ? 'these Vehicle Types' : 'this Vehicle Type'
        }? This operation cannot be undone.`
      );
      if (yes) {
        const r = await this.deleteVehicleTypes(items.map((e) => e.id));
        if (r.done) {
          this.$myalert.success(`${items.length > 1 ? 'Vehicle Types' : 'Vehicle Type'} deleted`);
          this.showForm = false;
          this.fetchItems();
        }
      }
    },
    closeForm() {
      this.showForm = false;
    },
  },
};
</script>

<style lang="scss">
.table-action {
  margin-left: 10px;
}
</style>
